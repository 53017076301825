import VueRouter from 'vue-router';
import { useAuthStore } from './stores/auth.js';

const onlyFor = (cb) => (to, from, next) => {
  const auth = useAuthStore();

  if (cb(auth)) {
    next();
  } else {
    next({ name: 'secure.dashboard' });
  }
};

const propsRegionId = (route) => ({
  regionId: route.params.regionId === 'all' ? undefined : Number(route.params.regionId),
});

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('./pages/PageLogin.vue'),
    meta: { title: 'Login' },
  },
  {
    path: '/logout',
    name: 'logout',
    component: () => import('./pages/PageLogout.vue'),
    meta: { title: 'Logout' },
  },
  {
    path: '/secure',
    component: () => import('./pages/PageSecure.vue'),
    children: [
      {
        path: '',
        redirect: '/secure/dashboard',
      },
      {
        path: 'agreements',
        name: 'secure.agreements',
        component: () => import('./pages/PageAgreements.vue'),
        meta: { title: 'All Agreements' },
      },
      {
        path: 'agreement/:agreementId',
        redirect: (to) => ({
          name: 'secure.agreementDetail',
          params: { agreementId: to.params.agreementId },
        }),
      },
      {
        path: 'agreements/:agreementId',
        name: 'secure.agreementDetail',
        props: (route) => ({ agreementId: Number(route.params.agreementId) }),
        component: () => import('./pages/PageAgreementDetail.vue'),
      },
      {
        path: 'agreements-saved',
        name: 'secure.agreementsSaved',
        component: () => import('./pages/PageAgreementsSaved.vue'),
        meta: { title: 'Saved Agreements' },
      },
      {
        path: 'agreement-duplicates',
        name: 'secure.agreementDuplicates',
        component: () => import('./pages/PageAgreementDuplicates.vue'),
        meta: { title: 'Duplicate Agreements' },
      },
      {
        path: 'agreement-imports',
        name: 'secure.agreementImports',
        component: () => import('./pages/PageAgreementImports.vue'),
        meta: { title: 'Agreement Imports' },
      },
      {
        path: 'agreement-payments',
        name: 'secure.agreementPayments',
        component: () => import('./pages/PageAgreementPayments.vue'),
        meta: { title: 'Payment Transactions' },
      },
      {
        path: 'claims',
        name: 'secure.claims',
        component: () => import('./pages/PageClaims.vue'),
        meta: { title: 'All Claims' },
      },
      {
        path: 'claims/:claimId',
        name: 'secure.claimDetail',
        props: (route) => ({ claimId: Number(route.params.claimId) }),
        component: () => import('./pages/PageClaimDetail.vue'),
      },
      {
        path: 'claim-pdfs/:claimId',
        name: 'secure.claimPdf',
        beforeEnter: (to, from, next) => {
          window.location =
            import.meta.env.VITE_DOC_URL + '/claim-pdfs/' + window.btoa(to.params.claimId);
        },
      },
      {
        path: 'crm',
        name: 'secure.crm',
        component: () => import('./pages/PageCrm.vue'),
      },
      {
        path: 'dashboard',
        name: 'secure.dashboard',
        component: () => import('./pages/PageDashboard.vue'),
        meta: { title: 'Dashboard' },
      },
      {
        path: 'dealers',
        name: 'secure.dealers',
        component: () => import('./pages/PageDealers.vue'),
        meta: { title: 'All Dealers' },
      },
      {
        path: 'jbw360-dealers',
        name: 'secure.jbw360Dealers',
        component: () => import('./pages/PageJbw360Dealers.vue'),
        meta: { title: 'JBW 360 Dealers' },
      },
      {
        path: 'trane-dealers',
        name: 'secure.traneDealers',
        component: () => import('./pages/PageTraneDealers.vue'),
        meta: { title: 'JBW 360 Dealers' },
      },
      {
        path: 'dealers/:dealerId',
        name: 'secure.dealerDetail',
        props: (route) => ({ dealerId: Number(route.params.dealerId) }),
        component: () => import('./pages/PageDealerDetail.vue'),
      },
      {
        path: 'dealer-materials',
        name: 'secure.dealerMaterials',
        component: () => import('./pages/PageDealerMaterials.vue'),
        meta: { title: 'Custom Marketing Materials' },
      },
      {
        path: 'dealer-merge',
        component: () => import('./pages/PageDealerMerge.vue'),
        beforeEnter: onlyFor((auth) => auth.canMergeDealers),
        children: [
          {
            path: '',
            name: 'secure.dealerMerge',
            meta: { title: 'JBW Dealer Merge' },
            component: () => import('./page-components/DealerMerge.vue'),
          },
          {
            path: 'hubspot',
            name: 'secure.dealerMergeHubspot',
            meta: { title: 'Hubspot Manual Merge' },
            component: () => import('./page-components/DealerMergeHubspot.vue'),
          },
          {
            path: 'logs',
            name: 'secure.dealerMergeLogs',
            meta: { title: 'Dealer Merge Logs' },
            component: () => import('./page-components/DealerMergeLogsTable.vue'),
          },
        ],
      },
      {
        path: 'match-dealers',
        name: 'secure.matchDealers',
        beforeEnter: onlyFor((auth) => auth.canMergeDealers),
        component: () => import('./pages/PageMatchDealers.vue'),
        meta: { title: 'Match Dealers' },
      },
      {
        path: 'dealer-profiles/:dealerId',
        name: 'secure.dealerProfile',
        props: (route) => ({ dealerId: Number(route.params.dealerId) }),
        component: () => import('./pages/PageDealerProfile.vue'),
      },
      {
        path: 'dealer-promotions',
        name: 'secure.dealerPromotions',
        component: () => import('./pages/PageDealerPromotions.vue'),
        meta: { title: 'Promotions' },
      },
      {
        path: 'dealer-search',
        name: 'secure.dealerSearch',
        component: () => import('./pages/PageDealerSearch.vue'),
        meta: { title: 'Dealer Search' },
      },
      {
        path: 'marketplace',
        name: 'secure.marketplace',
        component: () => import('./pages/PageMarketplace.vue'),
        meta: { title: 'Marketplace' },
      },
      {
        path: 'jb-financing',
        name: 'secure.jbFinancing',
        component: () => import('./pages/PageJbFinancing.vue'),
        meta: { title: 'JB Financing' },
      },

      {
        path: 'dealers-map',
        name: 'secure.dealersMap',
        component: () => import('./pages/PageDealersMap.vue'),
        meta: { title: 'Dealers Map' },
      },
      {
        path: 'dealer-lookup-map',
        name: 'secure.dealerLookupMap',
        component: () => import('./pages/PageDealerLookupMap.vue'),
        meta: { title: 'Dealer Lookup Map' },
      },
      {
        path: 'distributors',
        name: 'secure.distributors',
        component: () => import('./pages/PageDistributors.vue'),
        meta: { title: 'Distributor Management' },
      },
      {
        path: 'leads',
        name: 'secure.leads',
        component: () => import('./pages/PageLeads.vue'),
        meta: { title: 'All Leads' },
      },
      {
        path: 'price-books',
        name: 'secure.priceBooks',
        component: () => import('./pages/PagePriceBooks.vue'),
        meta: { title: 'Price Books' },
      },
      {
        path: 'products',
        name: 'secure.products',
        component: () => import('./pages/PageProducts.vue'),
        meta: { title: 'Products' },
      },
      {
        path: 'profile',
        name: 'secure.profile',
        component: () => import('./pages/PageProfile.vue'),
        meta: { title: 'My Profile' },
      },
      {
        path: 'reports/dealer-count-summary',
        name: 'secure.reports.dealerCountSummary',
        component: () => import('./pages/PageDealerCountSummary.vue'),
        meta: { title: 'Dealer Count Summary' },
      },
      {
        path: 'reports/notes-summary',
        name: 'secure.reports.notesSummary',
        component: () => import('./pages/PageNotesSummary.vue'),
        meta: { title: 'Notes Summary' },
      },
      {
        path: 'reports/sales-by-distributor',
        name: 'secure.reports.salesByDistributor',
        component: () => import('./pages/PageSalesByDistributor.vue'),
        meta: { title: 'Sales by Distributor' },
      },
      {
        path: 'reports/sales-by-metro',
        name: 'secure.reports.salesByMetro',
        component: () => import('./pages/PageSalesByMetro.vue'),
        meta: { title: 'Sales by Metro' },
      },
      {
        path: 'reports/sales-by-region',
        name: 'secure.reports.salesByRegion',
        component: () => import('./pages/PageSalesByRegion.vue'),
        meta: { title: 'Sales by Region' },
      },
      {
        path: 'reports/sales-by-region-2023',
        name: 'secure.reports.salesByRegion2023',
        component: () => import('./pages/PageSalesByRegion2023.vue'),
        meta: { title: '2023 Regions' },
      },
      {
        path: 'reports/sales-by-region-2025',
        name: 'secure.reports.salesByRegion2025',
        component: () => import('./pages/PageSalesByRegion2025.vue'),
        meta: { title: '2025 Regions' },
      },
      {
        path: 'reports/sales-by-state',
        name: 'secure.reports.salesByState',
        component: () => import('./pages/PageSalesByState.vue'),
        meta: { title: 'Sales by State' },
      },
      {
        path: 'salesportal-users',
        name: 'secure.salesPortalUsers',
        component: () => import('./pages/PageSalesPortalUsers.vue'),
        meta: { title: 'Sales Portal Users' },
      },
      {
        path: 'member-auto-login',
        name: 'secure.memberAutoLogin',
        component: () => import('./pages/PageMemberAutoLogin.vue'),
        meta: { title: 'JB Member Auto Login' },
      },
      {
        path: 'lgdfs',
        name: 'secure.lgdfs',
        component: () => import('./pages/PageLgdfs.vue'),
        meta: { title: 'LG DFS' },
      },
      {
        path: 'meus',
        component: () => import('./page-components/meus/PageMeus.vue'),
        meta: { title: 'MEUS' },
        children: [
          {
            path: '',
            name: 'secure.meus',
            redirect: (to) => {
              const auth = useAuthStore();

              return {
                name: 'secure.meus.dashboard',
                params: {
                  regionId: auth.canAccessAllRegions ? 'all' : auth.user.regions[0].regionid,
                },
              };
            },
          },
          {
            path: ':regionId',
            props: propsRegionId,
            component: () => import('./page-components/meus/PageRegion.vue'),
            beforeEnter: (to, from, next) => {
              const auth = useAuthStore();
              const regionId = to.params.regionId === 'all' ? 'all' : Number(to.params.regionId);

              if (auth.canAccessAllRegions || auth.canAccessRegion(regionId)) {
                next();
              } else {
                next({
                  name: to.name,
                  params: {
                    regionId: auth.user.regions[0].regionid,
                  },
                });
              }
            },
            children: [
              {
                path: '',
                redirect: (to) => {
                  return `/secure/meus/${to.params.regionId}/dashboard`;
                },
              },
              {
                path: 'dashboard',
                name: 'secure.meus.dashboard',
                props: propsRegionId,
                component: () => import('./page-components/meus/PageDashboard.vue'),
              },
              {
                path: 'contractors',
                name: 'secure.meus.contractors',
                props: propsRegionId,
                component: () => import('./page-components/meus/ContractorsTable.vue'),
              },
              {
                path: 'notes',
                name: 'secure.meus.notes',
                props: propsRegionId,
                component: () => import('./page-components/meus/ContractorNotesTable.vue'),
              },
              {
                path: 'agreements',
                name: 'secure.meus.agreements',
                props: propsRegionId,
                component: () => import('./page-components/meus/AgreementsTable.vue'),
              },
              {
                path: 'claims',
                name: 'secure.meus.claims',
                props: propsRegionId,
                component: () => import('./page-components/meus/ClaimsTable.vue'),
              },
              {
                path: 'models-plans',
                name: 'secure.meus.modelsPlans',
                props: propsRegionId,
                component: () => import('./page-components/meus/ModelsPlans.vue'),
              },
              {
                path: 'reports',
                name: 'secure.meus.reports',
                props: propsRegionId,
                component: () => import('./page-components/meus/ReportsTabs.vue'),
              },
              {
                path: 'materials',
                name: 'secure.meus.materials',
                props: propsRegionId,
                component: () => import('./page-components/meus/MaterialList.vue'),
              },
              {
                path: 'merge',
                name: 'secure.meus.merge',
                props: propsRegionId,
                component: () => import('./page-components/meus/MergeTabs.vue'),
              },
            ],
          },
        ],
      },
      {
        path: 'admin/email-templates',
        name: 'secure.admin.emailTemplates',
        beforeEnter: onlyFor((auth) => auth.isSuperAdmin),
        component: () => import('./pages/PageEmailTemplates.vue'),
        meta: { title: 'Email Templates' },
      },
      {
        path: 'admin/tools',
        name: 'secure.admin.tools',
        beforeEnter: onlyFor((auth) => auth.isSuperAdmin),
        component: () => import('./pages/PageTools.vue'),
        meta: { title: 'Admin Tools' },
      },
    ],
  },
  {
    path: '*',
    component: () => import('./pages/PageNotFound.vue'),
    meta: { title: 'Not Found' },
  },
];

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  const auth = useAuthStore();

  if (!auth.isLoggedIn && to.path.startsWith('/secure/')) {
    next({ name: 'login', query: { intended_url: to.fullPath } });
  } else if (auth.isLoggedIn && ['login'].includes(to.name)) {
    next({ name: auth.isSales ? 'secure.crm' : 'secure.dashboard' });
  } else {
    next();
  }
});

router.afterEach((to) => {
  if (to.meta?.title) {
    document.title = `${to.meta.title} | JB CRM`;
  }
});

export default router;
