import { computed } from 'vue';
import { defineStore } from 'pinia';
import { useLocalStorage } from '@vueuse/core';
import client from '../utils/client.js';
import userRoles from '../enums/userRoles.js';

export const useAuthStore = defineStore('auth', () => {
  const user = useLocalStorage('user', {});
  const isLoggedIn = computed(() => !!(user.value && user.value.api_token));
  const apiToken = computed(() => (isLoggedIn.value ? user.value.api_token : null));
  const isSuperAdmin = computed(
    () => isLoggedIn.value && user.value.userlevel === userRoles.SUPER_ADMIN
  );
  const isClaimAdmin = computed(
    () => isLoggedIn.value && user.value.userlevel === userRoles.CLAIM_ADMIN
  );
  const isVP = computed(() => isLoggedIn.value && user.value.id === 23);
  const isCsr = computed(() => isLoggedIn.value && user.value.userlevel === userRoles.CSR);
  const isSales = computed(() => isLoggedIn.value && user.value.department === 'sales');
  const isInsideSalesManager = computed(
    () => isLoggedIn.value && [9, 113, 150].includes(user.value.id)
  );
  const canAccessAllRegions = computed(() => isLoggedIn.value && user.value.can_access_all_regions);
  const canEditAgreement = computed(() => isSuperAdmin.value || isClaimAdmin.value || isCsr.value);
  const canEditClaim = computed(() => isSuperAdmin.value || isClaimAdmin.value);
  const canMergeDealers = computed(() => isSuperAdmin.value || isCsr.value || user.value.id === 23);

  async function login(apiToken) {
    try {
      const res = await client.get('/api/me', {
        params: {
          api_token: apiToken,
        },
      });

      user.value = res.data.data;
    } catch (e) {
      if (e.response.status === 401) {
        throw new Error('Failed to login.');
      }

      throw e;
    }
  }

  function logout() {
    user.value = null;
  }

  async function refresh() {
    const res = await client.get('/api/me');

    user.value = res.data.data;

    return res.data.data;
  }

  function hasRoleIn(roles) {
    return roles.includes(user.value.userlevel);
  }

  function canAccessRegion(regionId) {
    return canAccessAllRegions.value || user.value.associatedregions.includes(regionId);
  }

  return {
    user,
    isLoggedIn,
    apiToken,
    isSuperAdmin,
    isClaimAdmin,
    isVP,
    isCsr,
    isSales,
    isInsideSalesManager,
    canAccessAllRegions,
    canEditAgreement,
    canEditClaim,
    canMergeDealers,
    login,
    logout,
    refresh,
    hasRoleIn,
    canAccessRegion,
  };
});
